import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { AGREEMENT_ID, AGREEMENT_ID_KEY } from '@module/agreement-credits/components/constant/AgreementCreateCredits.const';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss'],
})
export class StepHeaderComponent {

  @Input() data: any;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private agreementCreditsService: AgreementCreditsService,
    private store: StoreService,
  ) { }

  goToRegistrationReport() {
    sessionStorage.setItem(AGREEMENT_ID, JSON.stringify(this.data.id));
    sessionStorage.setItem(AGREEMENT_ID_KEY, JSON.stringify(this.data.id));
    this.agreementCreditsService.storeCurrentData();
    this.store.storeCurrentUrl();
    this.router.navigate(
      this.helperService.getTranslatedPath(`/cfr-registration/cfr/RRPS/${this.data.accountId}`),
    );
  }

}
