import { IRawFormBase } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';

export const AGREEMENT_ID= 'AGREEMENT_ID';
export const AGREEMENT_ID_KEY = 'AgreementId';
export const AGREEMENT_DATA_KEY = 'AgreementData';
export const AGREEMENT_INPUT_DATA_KEY = 'AgreementInputData';
export const AGREEMENT_CURRENT_STEP_KEY = 'AgreementCurrentStep';

export const STEP1_PARTY_AGREEMENT_FORM = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'complianceYear',
        source: SOURCE_DATA_TYPE.CFR_COMPLAINCE_PERIOD,
        label: 'AGREEMENT_CREDITS.Step_1.compliancePeriod',
        placeholder: 'COMMON.unitClassList.selectItem',
        required: true,
        disabled: false,
        id: 'complianceYear',
      },
      {
        key: 'nameIdentifier',
        type: 'input',
        className: 'col-6',
        label: 'AGREEMENT_CREDITS.Step_1.identifierName',
        required: true,
        id: 'nameIdentifier',
      },
    ],
  },
  {
    groupFields: false,
    fieldGroup: [
      {
        key: '_info',
        type: 'template',
        className: 'col-12',
        label: 'AGREEMENT_CREDITS.Step_1.message',
        parse: true,
        translate: true,
      },
    ],
  },
  {
    groupFields: false,
    fieldGroup: [
      {
        key: 'opaName',
        type: 'agreement-facility-radio',
        className: 'col-12',
        required: true,
        options: [],
      },
    ],
  },
];

export const STEP1_FACILITY = [
  {
    groupFields: false,
    fieldGroup: [
      {
        key: 'name',
        type: 'agreement-facility-radio',
        className: 'col-12',
        required: true,
        options: [],
      },
    ],
  },
];

export const PREVIOUS_AGREEMENT_DETAILS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.editable',
        label: 'AGREEMENT_CREDITS.Step_3.selectPrevious',
        disabled: true,
        required: false,
        id: 'prevLegalName',
        hideExpression: true,
      },
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'opaAgreementId',
        source: SOURCE_DATA_TYPE.PREVIOUS_OPA,
        dataType: 3153097,
        label: 'AGREEMENT_CREDITS.Step_3.selectPrevious',
        placeholder: 'COMMON.unitClassList.selectItem',
        required: false,
        id: 'opaAgreementId',
        hideExpression: '!model.opaDetail.editable',
        clearable: true
      },
    ],
  },
];
export const STEP3_OTHER_PARTY_ORG_DETAILS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.legalName',
        label: 'AGREEMENT_CREDITS.Step_3.legalName',
        disabled: true,
        required: false,
        id: 'legalName',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.registrationNumber',
        label: 'AGREEMENT_CREDITS.Step_3.busNum',
        disabled: false,
        required: false,
        id: 'busNum',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.englishTradeName',
        label: 'AGREEMENT_CREDITS.Step_3.enTrade',
        disabled: false,
        required: false,
        id: 'enTrade',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.frenchTradeName',
        label: 'AGREEMENT_CREDITS.Step_3.frTrade',
        disabled: false,
        required: false,
        id: 'frTrade',
      },
    ],
  },
];

export const STEP3_RCA_ORG_DETAILS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'registrationAccount.legalName',
        label: 'AGREEMENT_CREDITS.Step_3.legalName',
        disabled: true,
        required: false,
        id: 'legalName',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'registrationAccount.registrationNumber',
        label: 'AGREEMENT_CREDITS.Step_3.busNum',
        disabled: false,
        required: false,
        id: 'busNum',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'registrationAccount.englishTradeName',
        label: 'AGREEMENT_CREDITS.Step_3.enTrade',
        disabled: false,
        required: false,
        id: 'enTrade',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'registrationAccount.frenchTradeName',
        label: 'AGREEMENT_CREDITS.Step_3.frTrade',
        disabled: false,
        required: false,
        id: 'frTrade',
      },
    ],
  },
];
export const STEP4_AUTH_OFFICIAL_INFO: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.title',
        label: 'AGREEMENT_CREDITS.Step_4.title',
        disabled: false,
        required: true,
        id: 'AOtitle',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.name',
        label: 'AGREEMENT_CREDITS.Step_4.name',
        disabled: false,
        required: true,
        id: 'AOname',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.businessTelephone',
        label: 'AGREEMENT_CREDITS.Step_4.telNumber',
        disabled: false,
        required: true,
        id: 'AOtelNumber',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.email',
        label: 'AGREEMENT_CREDITS.Step_4.email',
        disabled: false,
        required: true,
        id: 'AOemail',
      },
    ],
  },
];

export const STEP4_RC_AUTH_OFFICIAL_INFO = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.title',
        label: 'AGREEMENT_CREDITS.Step_4.title',
        disabled: false,
        required: true,
        id: 'RCtitle',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.name',
        label: 'AGREEMENT_CREDITS.Step_4.name',
        disabled: false,
        required: true,
        id: 'RCname',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.businessTelephone',
        label: 'AGREEMENT_CREDITS.Step_4.telNumber',
        disabled: false,
        required: true,
        id: 'RCtelNumber',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.authorizedOfficial.email',
        label: 'AGREEMENT_CREDITS.Step_4.email',
        disabled: false,
        required: true,
        id: 'RCemail',
      },
    ],
  },
];
export const STEP4_CONTACT_PERSON_INFO: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        key: `opaDetail.contactPerson.sameAsAuthorizedOfficial`,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.sameAsAuthorizedOfficial',
        disabled: false,
        changeExpr: `this.disableForm($event, field,  \'opaDetail.contactPerson\');
                this.cloneFormGroup($event, field, \'opaDetail.authorizedOfficial\',
                \'opaDetail.contactPerson\')`,
        required: false,
        className: 'col-12',
        id: 'CPsameAsAuthorizedOfficial',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.contactPerson.title',
        label: 'AGREEMENT_CREDITS.Step_4.title',
        disabled: false,
        required: true,
        id: 'CPtitle',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.contactPerson.name',
        label: 'AGREEMENT_CREDITS.Step_4.name',
        disabled: false,
        required: true,
        id: 'CPname',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.contactPerson.businessTelephone',
        label: 'AGREEMENT_CREDITS.Step_4.telNumber',
        disabled: false,
        required: true,
        id: 'CPtelNumber',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'opaDetail.contactPerson.email',
        label: 'AGREEMENT_CREDITS.Step_4.email',
        disabled: false,
        required: true,
        id: 'CPemail',
      },
    ],
  },
];

export const STEP5_SIGN_AGREEMENT: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        className: 'col-12',
        key: 'agreed',
        label: `AGREEMENT_CREDITS.Step_6.confirmationLabel`,
        disabled: false,
        required: true,
        requiredTrue: true,
        id: `AGREEMENT_CREDITS.Step_6.confirmationLabel`,
      },
      {
        type: 'readonly-input',
        className: 'col-6',
        key: 'signature',
        label: `AGREEMENT_CREDITS.Step_6.name`,
        disabled: true,
        required: false,
        id: `AGREEMENT_CREDITS.Step_6.name`,
      },
      {
        type: 'app-formly-datepicker',
        className: 'col-6',
        key: 'signedDate',
        label: `AGREEMENT_CREDITS.Step_6.submittedOn`,
        disabled: true,
        required: false,
        id: `AGREEMENT_CREDITS.Step_6.submittedOn`,
      },
    ],
  },
];

export const VIEW_AGREEMENT = {
  uiUri: '/agreement-credits/wizard/agreement/${id}',
  params: ['id'],
};

export const EDIT_AGREEMENT = {
  uiUri: '/agreement-credits/wizard/agreement/edit/${id}',
  params: ['id'],
};

export const VIEW_UPLOADED_AGREEMENT = {
  uiUri: '/agreement-credits/wizard/agreement/upload/${id}',
  params: ['id'],
};

export const AGREEMENT_ACTIONS = {
  REVIEW_AGREEMENT: VIEW_AGREEMENT,
  VIEW: VIEW_AGREEMENT,
  EDIT: EDIT_AGREEMENT,
  UPLOAD_SIGNED_AGREEMENT: VIEW_UPLOADED_AGREEMENT,
  VIEW_SIGNED_AGREEMENT: VIEW_UPLOADED_AGREEMENT,
  DISCARD: {
    confirmationMessage: 'confirmationMessageAgreementDiscard',
  },
};
