import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ADMIN_WORKFLOW_CONFIG } from '@module/admin/services/admin-management.service';
import { statusUriMap, CREDIT_CANCELLATION } from '@module/unit-management/components/unit-list/unit-list.const';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { cancelAction } from '@core/models/filter-types.model';
import { ERP_CARBON_INTENSITY_FLD } from '@shared/components/steps/constants/erp-application.const';
import { NOE_CREDIT_STATUSES } from '@module/unit-management/unit-management.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/helper.service";
import * as i5 from "../../../core/store/store.service";
import * as i6 from "../../../shared/services/modal.service";
export const CREDIT_SERVICE = '/obps-service';
export const UNIT_SEARCH = '/unit/findAll';
export const UNIT_RECOG_DATA = '/obps-service/creditAction/createReconizedUnit';
export const UPDATE_UNIT_DATA = '/obps-service/creditAction/splitUnit';
export const UNIT_DETAIL = '/creditAction/loadByUnitId/';
export const CREDIT_ACTION_DETAIL = '/creditAction/loadByCreditActionId/';
export const FIND_RECOGNIZED_UNIT = '/remittanceAction/findRecognizeUnit/';
export const DICT_UNIT_CLASS_URI = '/account-service/lookup/getRuUnitClass';
export const DICT_COMPLIANCE_YEAR_URI = '/account-service/lookup/compliancePeriods';
export const DICT_GHG_YEAR_URI = '/account-service/lookup/getGhgYears';
export const DICT_OFFSET_PROTOCOL_URI = '/account-service/lookup/getOffsetProtocol';
export const DICT_OFFSET_PROTOCOL_JURISDTICTION_URI = '/account-service/lookup/getOffsetProtocol/';
export const DICT_OFFSET_PROTOCOL_VERSION_URI = '/account-service/lookup/getOffsetProtocolVersion';
export const DICT_ACCOUNT_NAMES_URI = '/account-service/lookup/facilityNames';
export const GET_OBLIGATIONS_URI = '/registry-server/obligation/getAccountObligations/';
export const DICT_JURISDICTIONS_URI = '/account-service/lookup/getRecognizeUnitJurisdiction';
export const DICT_SINGLE_SERIAL_NUMBER = '/account-service/lookup/isSingleSerialNumberFormat/';
export const OBLIGATION_BY_OBLIGATION_ID = '/registry-server/obligation/getByObligationId/';
export const RU_REMITTANCE_REPORT = '/registry-server/report/getRuRemittanceReport';
export const NOTES_SEARCH = '/offset-service/notes/search';
export const EIA_UNIT_SEARCH = '/offset-service/eia';
export const ID_STATUSES = [
    'PENDING_ISSUANCE_ADMIN',
    'PENDING_ISSUANCE_ADMIN1',
    'CREDIT_LIST',
    ...NOE_CREDIT_STATUSES,
];
const ACCOUNT_DETAILS = '/account-service/account/detail/';
const GET_BULK_ACTION_ID = '/obps-service/creditAction/search';
const REQ_INSUFFICIENT_CREDIT_TO_CANCEL = '/obps-service/unit/requestInsufficientCreditsToCancel';
const UNIT_GET_INSUFFICIENT_CREDIT = '/obps-service/unit/getInsufficientCredit/';
const UNIT_INSUFFICIENT_CREDIT_SUBMISSION = '/obps-service/unit/insufficientCreditSubmission';
const UNIT_INSUFFICIENT_CREDIT_NOTICE_UPDATE = '/obps-service/unit/sendNoticeForCreditUpdate';
const UNIT_CANCEL_EXCESS_CREDIT = '/obps-service/unit/cancelExcessCredits';
const CHECK_FOR_ACTIVE_CCM = '/cfr-service/creditClearance/checkCreditCcmSetup';
export const CCR_FUEL_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForFuel';
export const CCR_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export class UnitManagementService extends AbstractPaginationService {
    constructor(http, translate, router, helperService, store, modalService) {
        super();
        this.http = http;
        this.translate = translate;
        this.router = router;
        this.helperService = helperService;
        this.store = store;
        this.modalService = modalService;
    }
    getAll(pagination, filterState = {}, uri = `${CREDIT_SERVICE}${UNIT_SEARCH}`) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.serviceUrl}${uri ? uri : (CREDIT_SERVICE + UNIT_SEARCH)}`, filterState, { params });
        // return this.http.post<IApp.IUnitPage>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_SEARCH }`, filterState, { params });
    }
    creditAction(uri, payload) {
        return this.http.post(`${environment.apiUrl}${CREDIT_SERVICE}${uri}`, payload);
        // return this.http.post<IOkResponse>(`http://localhost:8088/api${ CREDIT_SERVICE }${ uri }`, payload);
    }
    pdfPreviewAction(uri, payload, expectErrors = false) {
        return this.http.post(`${environment.apiUrl}${CREDIT_SERVICE}${uri}`, payload);
    }
    creditRecognizedAction(uri, payload) {
        return this.http.post(`${environment.apiUrl}${CREDIT_SERVICE}${uri}`, payload);
        // return this.http.post<IOkResponse>(`http://localhost:8088/api${ CREDIT_SERVICE }${ uri }`, payload);
    }
    getUnitDetails(unitId) {
        return this.http.get(`${environment.apiUrl}${CREDIT_SERVICE}${UNIT_DETAIL}${unitId}`);
        //  return this.http.get<IApp.ICreditActionData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_DETAIL }${unitId}`);
    }
    loadByCreditActionId(actionId) {
        return this.http.get(`${environment.apiUrl}${CREDIT_SERVICE}${CREDIT_ACTION_DETAIL}${actionId}`);
        // return this.http.get<IApp.ICreditActionData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ CREDIT_ACTION_DETAIL }${actionId}`);
    }
    getRecognizedUnit(unitId) {
        return this.http.get(`${environment.apiUrl}${CREDIT_SERVICE}${FIND_RECOGNIZED_UNIT}${unitId}`);
        // return this.http.get<IRecognizedCreditRemittanceData>(`http://localhost:8088/api${ CREDIT_SERVICE }${ FIND_RECOGNIZED_UNIT }${unitId}`);
    }
    getUnitType() {
        return this.http.get(`${environment.apiUrl}${DICT_UNIT_CLASS_URI}`);
    }
    getComplianceYear() {
        return this.http.get(`${environment.apiUrl}${DICT_COMPLIANCE_YEAR_URI}`);
    }
    getGhgYear() {
        return this.http.get(`${environment.apiUrl}${DICT_GHG_YEAR_URI}`);
    }
    getOffsetProtocol() {
        return this.http.get(`${environment.apiUrl}${DICT_OFFSET_PROTOCOL_URI}`);
    }
    getOffsetProtocolByJursidiction(jurisidiction) {
        return this.http.get(`${environment.apiUrl}${DICT_OFFSET_PROTOCOL_JURISDTICTION_URI}${jurisidiction}`);
    }
    getOffsetProtocolVersion() {
        return this.http.get(`${environment.apiUrl}${DICT_OFFSET_PROTOCOL_VERSION_URI}`);
    }
    getAccountNames() {
        return this.http.get(`${environment.apiUrl}${DICT_ACCOUNT_NAMES_URI}`);
    }
    getObligation(accountId) {
        return this.http.get(`${environment.apiUrl}${GET_OBLIGATIONS_URI}${accountId}`);
    }
    getProgramWorkflowConfig() {
        const params = new HttpParams();
        return this.http.post(`${environment.apiUrl}${ADMIN_WORKFLOW_CONFIG}`, { params });
    }
    getRecognizeUnitJurisdiction() {
        return this.http.get(`${environment.apiUrl}${DICT_JURISDICTIONS_URI}`);
    }
    getRUAll(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${RU_REMITTANCE_REPORT}`, filterState, { params });
        // return this.http.post<IApp.IUnitPage>(`http://localhost:8088/api${ CREDIT_SERVICE }${ UNIT_SEARCH }`, filterState, { params });
    }
    isSingleSerialNumberFormat(id) {
        return this.http.get(`${environment.apiUrl}${DICT_SINGLE_SERIAL_NUMBER}${id}`);
    }
    getObligationByObligationID(obligationId) {
        const url = `${environment.apiUrl}${OBLIGATION_BY_OBLIGATION_ID}${obligationId}`;
        return this.http.get(url);
    }
    updateUnitData(payload) {
        return this.http.post(`${environment.apiUrl}${UPDATE_UNIT_DATA}`, payload);
    }
    validateQuantity(field, errorMsg = 'quantityMustBePositiveNumber') {
        if (isNaN(field.formControl.value) || field.formControl.value % 1 != 0
            || field.formControl.value <= 0) {
            field.formControl.setErrors({ 'server-side': this.translate.instant(errorMsg) });
        }
    }
    calculateEIAQuantity(quantity, percentage) {
        if (quantity && percentage) {
            return Math.ceil((quantity * percentage) / 100);
        }
        return null;
    }
    validateQuantityWithZero(field, quantity) {
        if (isNaN(field.formControl.value) || field.formControl.value % 1 != 0) {
            field.formControl.setErrors({ 'server-side': this.translate.instant('quantityMustBePositiveNumber') });
        }
        else if (quantity < field.formControl.value) {
            field.formControl.setErrors({ 'server-side': this.translate.instant('refundQuantityMustBeLessThanUnitQuantity') });
        }
    }
    onActionSelected(action, selectedUnits) {
        const creditActionData = {};
        if (selectedUnits) {
            if (selectedUnits['ocrUnitList']) {
                selectedUnits = selectedUnits['ocrUnitList'];
            }
            creditActionData.creditBlockList = selectedUnits.map(unit => ({
                unitId: unit.id,
                facilityName: unit.facilityName,
                quantity: unit.quantity,
                unitClassCode: unit.unitClassCode,
                standardId: unit.standardId,
                version: unit.version,
                issuanceDateStr: unit.issuanceDateStr,
                remittedComplianceYear: unit.remittedComplianceYear,
                serialNumber: unit.serialNumber,
                complianceYear: unit.complianceYear,
                accountId: unit.accountId,
                projectProjectId: unit.projectProjectId,
                projectId: unit.projectId,
                projectName: unit.projectName,
                issuanceYear: unit.issuanceDateStr ? unit.issuanceDateStr.substring(0, 4) : null,
                protocol: unit.protocol,
                jurisdictionStr: unit.jurisdictionStr,
                projectType: unit.project && unit.project.activity && unit.project.activity.activityType ?
                    unit.project.activity.activityType.code : null,
                creditClass: unit.creditClass,
                creditGroupId: unit.creditGroupId,
                creditTypeCode: unit.creditTypeCode,
                creditGroupName: unit.creditGroupName,
                creditTypeId: unit.creditTypeId,
                fuelType: unit.fuelType,
                fuelId: unit.fuelId,
                issuedQuantity: unit.issuedQuantity,
                creditCreationReportDetailDto: unit.creditCreationReportDetailDto,
                // [CFR_CALCULATED_CI_VOLUME_FLD]: unit.creditGroupName.includes('VMG') || unit.creditGroupName.includes('VMD') ? unit.associatedVolume: null,
                associatedVolume: unit.creditGroupName && (unit.creditGroupName.includes('VMG') || unit.creditGroupName.includes('VMD')) ? unit.associatedVolume : null,
                [ERP_CARBON_INTENSITY_FLD]: unit.finalCi,
                approvedCliValue: unit.finalCi,
            }));
            creditActionData.discloseComment = false;
            creditActionData.withdrawn = false;
            creditActionData.documentList = [];
            creditActionData.id = null;
            if (selectedUnits && selectedUnits.length > 0) {
                const unit = selectedUnits[0];
                creditActionData.standardId = unit.standardId;
                creditActionData.complianceYear = unit.remittedComplianceYear;
                creditActionData.accountId = unit.accountId;
                creditActionData.facilityName = unit.facilityName;
                creditActionData.certificateNumber = unit.certificateNumber;
                creditActionData.issuedQuantity = unit.issuedQuantity;
                creditActionData.jurisdiction = unit.jurisdiction;
                if (unit.account) {
                    creditActionData.sourceAccount = Object.assign({}, creditActionData.sourceAccount, { jurisdiction: unit.account.jurisdiction, legalName: unit.account.legalName, englishTradeName: unit.account.englishTradeName, frenchTradeName: unit.account.frenchTradeName, registrationNumber: unit.account.registrationNumber, civicAddress: unit.account.civicAddress, postalAddress: unit.account.postalAddress });
                    if (unit.account.contactPerson) {
                        const contactPerson = unit.account.contactPerson;
                        creditActionData.sourceAccount = Object.assign({}, creditActionData.sourceAccount, { contactPerson: {
                                civicAddress: contactPerson.civicAddress,
                                postalAddress: contactPerson.postalAddress,
                                name: contactPerson.name,
                                title: contactPerson.title,
                                businessTelephone: contactPerson.businessTelephone,
                                email: contactPerson.email,
                            } });
                    }
                    if (this.store.user.isCFR() && unit.account.authorizedAccountRepresenttive) {
                        const authorizedOfficial = unit.account.authorizedAccountRepresenttive;
                        creditActionData.sourceAccount = Object.assign({}, creditActionData.sourceAccount, { authorizedOfficial: {
                                fullName: authorizedOfficial.fullName,
                                title: authorizedOfficial.title,
                                businessTelephone: authorizedOfficial.businessTelephone,
                                email: authorizedOfficial.email,
                                civicAddress: authorizedOfficial.civicAddress,
                                postalAddress: authorizedOfficial.postalAddress,
                            } });
                    }
                }
            }
        }
        return creditActionData;
    }
    viewUnitDetails(unitClassCode, unit, eia = false, status = null) {
        if (unit.status === 'PROPOSED_REMITTANCE' && !this.store.user.checkIfTransactionProposer(unit.accountId)) {
            const creditActionData = {};
            creditActionData.id = unit.creditActionId;
            this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/remittance/SCR/${unit.obligationId}/${unit.creditActionId}`), { state: { data: creditActionData } });
        }
        else {
            const prefix = status ? status : unit.status === 'REJECTED' ? unit.actionType : unit.status;
            let key = `${unitClassCode}_${unit.status}`;
            if (prefix !== null) {
                key = `${unitClassCode}_${prefix}`;
            }
            if (unit.status === 'REFUND_REQUESTED' || unit.status === 'PENDING_REMIT_REVIEW_REFUND'
                || unit.status === 'PENDING_REMIT_REVIEW_REFUND1') {
                key = `${key}_${unit.actionType}`;
            }
            let id = unit.unitClassCode === 'RU' ||
                ID_STATUSES.includes(unit.status) || key === 'SCR_REJECTED'
                ? unit.id : unit.creditActionId;
            const creditActionData = {};
            creditActionData.id = id;
            if (!id || unit.status === 'ACTIVE' || status && status === 'ACTIVE' ||
                unit.status === 'PLEDGED' ||
                NOE_CREDIT_STATUSES.includes(unit.status)) {
                id = 'unit/' + unit.id;
            }
            let scrKey = key.replace('OCR', 'SCR');
            scrKey = scrKey.replace('OFFSET', 'SURPLUS');
            const uri = statusUriMap[key] ? statusUriMap[key] : statusUriMap[scrKey];
            let path = `/unit-management/list${uri}`;
            if (path.indexOf(CREDIT_CANCELLATION) > -1 && eia) {
                path = `${path}-eia`;
            }
            path = `${path}/${id}`;
            creditActionData.refundQuantity = unit.refundQuantity;
            this.router.navigate(this.helperService.getTranslatedPath(path), { state: { data: creditActionData, status: unit.status } });
        }
    }
    addCheckBoxAction(data, tableDetails) {
        const actionModel = tableDetails.tableFilter.actionModel;
        actionModel[1].options = [];
        if (data.length > 0 && data[data.length - 1].status === 'ACTIVE') {
            actionModel[1].options.push(cancelAction);
        }
        return actionModel;
    }
    getDiscussions(id, dir = 'DESC') {
        let params = new HttpParams();
        params = params.append('dir', dir);
        return this.http.post(`${environment.apiUrl}${NOTES_SEARCH}`, { entityId: id, entityType: 'CREDIT_ACTION' }, { params });
    }
    getAccountDetails(id, otherAccount) {
        let url = `${environment.apiUrl}${ACCOUNT_DETAILS}${id}`;
        if (otherAccount) {
            url += `?otherAccount=${otherAccount}`;
        }
        return this.http.get(url);
    }
    getBulkActionId(accountId) {
        return this.http.post(`${environment.apiUrl}${GET_BULK_ACTION_ID}`, { accountId });
    }
    sendInsufficientCreditNotice(payload) {
        return this.http.post(`${environment.apiUrl}${REQ_INSUFFICIENT_CREDIT_TO_CANCEL}`, payload);
    }
    getInsufficientCreditById(id) {
        return this.http.get(`${environment.apiUrl}${UNIT_GET_INSUFFICIENT_CREDIT}${id}`);
    }
    submitInsufficientCredit(payload) {
        return this.http.post(`${environment.apiUrl}${UNIT_INSUFFICIENT_CREDIT_SUBMISSION}`, payload);
    }
    sendInsufficientCreditNoticeUpdate(payload) {
        return this.http.post(`${environment.apiUrl}${UNIT_INSUFFICIENT_CREDIT_NOTICE_UPDATE}`, payload);
    }
    cancelExcessCredits(payload) {
        return this.http.post(`${environment.apiUrl}${UNIT_CANCEL_EXCESS_CREDIT}`, payload);
    }
    checkForActiveCCM() {
        return this.http.get(`${environment.apiUrl}${CHECK_FOR_ACTIVE_CCM}`);
    }
    getFuelEnergyDensity(fuelId) {
        return this.http.get(`${environment.apiUrl}${CCR_FUEL_ENERGY_DENSITY_URI}/${fuelId}`);
    }
    getCFRReferenceCarbonIntensity(year, fuelId) {
        return this.http.get(`${environment.apiUrl}${CCR_REFERENCE_CARBON_INTENSITY_FOR_FUEL}/${year}/${fuelId}`);
    }
    getAlphanumericIDofCI(payload) {
        return this.http.post(`${environment.apiUrl}${CCR_CARBON_INTENSITY_IDS_LOOKUP_URI}`, payload);
    }
    showErrorMessage(message) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
}
UnitManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnitManagementService_Factory() { return new UnitManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.StoreService), i0.ɵɵinject(i6.ModalService)); }, token: UnitManagementService, providedIn: "root" });
