import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CAR_CI_OF_FUEL, CAR_INSTRUMENT_CI_ID } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import { getAlphanumericIdInput, getCarbonIntensity } from '@module/credit-adjustment-report/routines/credit-adjustment-report.routine';
import { CCR_ADJUSTMENT_STEP_PREFIX, CCR_TRANSLATE_PREFIX } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { CCR_LIQUID_COMPLIANCE_CREDITS_FLD, CCR_LO_CI_FUEL_ENERGY_DENSITY_FLD } from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { LocalPaginationService } from '@shared/services/local-pagination.service';
export class CcrAdjustmentsUnderSectionViewInputDetailsComponent {
    constructor(data, dialogRef, formFactoryService, service, lookupService, localPaginationService, uploadDocumentService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.formFactoryService = formFactoryService;
        this.service = service;
        this.lookupService = lookupService;
        this.localPaginationService = localPaginationService;
        this.uploadDocumentService = uploadDocumentService;
        this.form = new FormGroup({});
        this.model = {};
        this.tableData = { content: [] };
        this.visible = true;
        this.separatorMask = 'separator.5';
        this.commaSeparator = ',';
        this.translationPrefix = `${CCR_TRANSLATE_PREFIX}${CCR_ADJUSTMENT_STEP_PREFIX}`;
        this.usedUnitsMap = {};
    }
    ngOnInit() {
        this.translationPrefix = this.translationPrefix + `_${this.service.currentStep.code}`;
        if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
            const refRportIds = this.service.currentData.reportDetails.filter(report => (report.calculatedComplianceCredits && report.ciApplicationId !== this.data.rowData.ciId)).map(report => report.referredReportDetailId);
            this.data.viewDetailsData = this.data.viewDetailsData.filter(report => !(!report.id && (refRportIds.includes(report.referredReportDetailId))));
            this.setDocConfig();
            this.setTableData();
        }
        else {
            this.setFields();
            this.setUsedUnitsMap();
            this.updateCalculatedComplianceCredits();
            this.setTableData();
        }
        this.setTableConfig();
    }
    setFields() {
        this.fields = this.formFactoryService.configureForm([
            {
                groupFields: false,
                className: 'col-12',
                fieldGroup: [
                    getAlphanumericIdInput(),
                    getCarbonIntensity('carbonIntensityView', CAR_CI_OF_FUEL)
                ]
            }
        ]);
    }
    setTableConfig() {
        let configName = '';
        if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
            configName = 'ANNUAL_STEP-SUB_SEC_9_3_DETAIL';
        }
        else {
            configName = this.data.rowData.ciModel.activityType === 'CI_CHARGING_STATIONS' ? 'CI_CHARGING_STATIONS_ACCR_SECTION_90' : 'ANNUAL_STEP-SUB_SEC_90_DETAIL';
        }
        this.lookupService.getUnderSectionTableConfig(configName).subscribe(result => {
            if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
                this.configureSection_9_3_Cols(result);
            }
            else {
                this.configureSection_90_88_Cols(result);
            }
            this.scrollToTop();
        });
    }
    scrollToTop() {
        setTimeout(() => {
            this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
        }, 500);
    }
    setTableData() {
        this.data.viewDetailsData.forEach(element => {
            element.quarterAndYear = `${element.nameOfQuarter}-${element.year}`,
                element.complianceCreditsCreated = element.updatedComplianceCredit;
        });
        if (this.data.viewDetailsData.length) {
            this.localPaginationService.data = this.data.viewDetailsData;
            this.tableData = this.localPaginationService.getPage({ page: 0 });
            this.model = {
                [CAR_INSTRUMENT_CI_ID]: this.data.viewDetailsData[0].referredReportDetail ? this.data.viewDetailsData[0].referredReportDetail.alphanumericId : this.data.viewDetailsData[0].alphanumericId,
                [CAR_CI_OF_FUEL]: this.data.viewDetailsData[0].referredReportDetail ? this.data.viewDetailsData[0].referredReportDetail.approvedCliValue : this.data.viewDetailsData[0].approvedCliValue
            };
        }
    }
    onVolumneAllocation(data) {
        data._quantityAvailableRaw = data.fixedRemaining - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId] + Number(data.otherUsedQuantity));
        data._quantityAvailable = Math.max(data._quantityAvailableRaw, 0);
        this.calculatedCredits(data);
    }
    calculatedCredits(data) {
        let originalCalc = 0; // with original clivalue
        const approvedCliValue = data.referredReportDetail ? data.referredReportDetail.approvedCliValue : data.approvedCliValue;
        if (data.oldStep.code === 'STEP-2') {
            const ciLiquidLowCIFuel = this.data.rowData.ciModel.approvedCarbonIntensityRounded || 0;
            const energyDensity = Number(data[CCR_LO_CI_FUEL_ENERGY_DENSITY_FLD]) || 0;
            const referenceCarbonIntensity = data.referencedCarbonIntensityForFuelDto ? data.referencedCarbonIntensityForFuelDto.fuelValue : 0;
            const calc = (referenceCarbonIntensity - ciLiquidLowCIFuel) * (data.complianceCreditQuantity) * energyDensity * Math.pow(10, -6);
            originalCalc = (referenceCarbonIntensity - approvedCliValue) * (data.complianceCreditQuantity) * energyDensity * Math.pow(10, -6);
            data[CCR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.max(Math.round(calc < 0 ? 0 : calc) - Math.round(originalCalc < 0 ? 0 : originalCalc), 0);
        }
        else if (data.oldStep.code === 'STEP-3') {
            const ciLiquidLowCIFuel = this.data.rowData.ciModel.approvedCarbonIntensityRounded || 0;
            const energyDensity = Number(data[CCR_LO_CI_FUEL_ENERGY_DENSITY_FLD]) || 0;
            const defaultRegSpecCi = data.approvedCarbonIntensityDto.defaultSpecRegValue;
            const referenceCarbonIntensity = data.referencedCarbonIntensityForFuelDto ? data.referencedCarbonIntensityForFuelDto.fuelValue : 0;
            const refCILiquid = data.referencedCarbonIntensityDto ? data.referencedCarbonIntensityDto.fuelValue : 0;
            const calc = ((refCILiquid - ciLiquidLowCIFuel) + (defaultRegSpecCi - referenceCarbonIntensity)) * (data.complianceCreditQuantity) * energyDensity * Math.pow(10, -6);
            originalCalc = ((refCILiquid - approvedCliValue) + (defaultRegSpecCi - referenceCarbonIntensity)) * (data.complianceCreditQuantity) * energyDensity * Math.pow(10, -6);
            data[CCR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.max(Math.round(calc < 0 ? 0 : calc) - Math.round(originalCalc < 0 ? 0 : originalCalc), 0);
        }
        else if (['STEP-4', 'STEP-5'].includes(data.oldStep.code)) {
            const efficiencyRatio = data.energyEfficiencyRatio || 0;
            const ciElectricity = this.data.rowData.ciModel.approvedCarbonIntensityRounded || 0;
            const quantityElectricity = Number(data.complianceCreditQuantity) || 0;
            const referenceCarbonIntensity = data.referencedCarbonIntensityForFuelDto ? data.referencedCarbonIntensityForFuelDto.fuelValue : 0;
            const calc = (efficiencyRatio * referenceCarbonIntensity - ciElectricity) * quantityElectricity * 3.6 * Math.pow(10, -6);
            originalCalc = (efficiencyRatio * referenceCarbonIntensity - approvedCliValue) * quantityElectricity * 3.6 * Math.pow(10, -6);
            data[CCR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.max(Math.round(calc < 0 ? 0 : calc) - Math.round(originalCalc < 0 ? 0 : originalCalc), 0);
        }
        else if (data.oldStep.code === 'STEP-6') {
            const efficiencyRatio = data.energyEfficiencyRatio || 0;
            const energyDensity = Number(data[CCR_LO_CI_FUEL_ENERGY_DENSITY_FLD]) || 0;
            const ciElectricity = this.data.rowData.ciModel.approvedCarbonIntensityRounded || 0;
            const quantityElectricity = Number(data.complianceCreditQuantity) || 0;
            const referenceCarbonIntensity = data.referencedCarbonIntensityForFuelDto ? data.referencedCarbonIntensityForFuelDto.fuelValue : 0;
            const calc = (efficiencyRatio * referenceCarbonIntensity - ciElectricity) * quantityElectricity * energyDensity * Math.pow(10, -6);
            originalCalc = (efficiencyRatio * referenceCarbonIntensity - approvedCliValue) * quantityElectricity * energyDensity * Math.pow(10, -6);
            data[CCR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.max(Math.round(calc < 0 ? 0 : calc) - Math.round(originalCalc < 0 ? 0 : originalCalc), 0);
        }
    }
    calculateTotalCredits(data) {
        data.totalComplianceCreditQuantity = (+data.liquidComplianceCredits || 0) + (+data.gaseousComplianceCredits || 0);
    }
    onAlphanumericIdChange(data) {
        data.ciSectionCode = data.alphanumericId;
    }
    onapprovedCliValueChange(data) {
        data.carbonIntensityFuel = data.approvedCliValue;
    }
    onPaginationChanged() {
    }
    updateColumnsConfiguration(cols) {
        if (this.data.rowData.defaultCiCode) {
            const totalComplianceCreditQuantity = {
                header: 'complianceCreditQuantity',
                width: AUTO_WIDTH_FOR_COLUMNS,
                field: 'totalComplianceCreditQuantity',
                fieldType: 'Numeric'
            };
            const allocateQuantity = {
                header: 'allocatedQuantity',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.allocateVolColTemplate,
            };
            const quantityAvailable = {
                header: 'quantityAvailable',
                width: AUTO_WIDTH_FOR_COLUMNS,
                field: '_quantityAvailable',
                fieldType: 'Numeric'
            };
            const index = cols.findIndex((col) => col.field === 'complianceCreditQuantity');
            if (index !== -1) {
                cols.splice(index, 1, totalComplianceCreditQuantity, allocateQuantity, quantityAvailable);
            }
        }
        return [...cols];
    }
    save() {
        if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
            if (this.tableData.content.some(data => (data.approvedCliValue && !(data.approvedCliValue >= 0) || data.complianceCreditQuantity && !(data.complianceCreditQuantity >= 0) || data.energyDensity && !(data.energyDensity >= 0) || data.liquidComplianceCredits && !(data.liquidComplianceCredits >= 0) || data.gaseousComplianceCredits && !(data.gaseousComplianceCredits >= 0)) ||
                (!data.alphanumericId && (data.approvedCliValue || data.complianceCreditQuantity || data.energyDensity || data.liquidComplianceCredits || data.gaseousComplianceCredits))) || !this.uploadDocumentService.checkMandatoryDocuments(this.documentUploadConfig, this.uploadedDocuments)) {
                return;
            }
        }
        else if (this.tableData.content.some(data => data._quantityAvailableRaw < 0 || (data.complianceCreditQuantity && !(data.complianceCreditQuantity >= 0)) || (data.complianceCreditsCreated && !(data.complianceCreditsCreated >= 0)))) {
            return;
        }
        this.dialogRef.close(this.data);
    }
    close() {
        this.dialogRef.close(false);
    }
    get headerText() {
        return this.data.action === 'VIEW' ?
            'CREDIT_ADJUSTMENT_REPORT.reportStep.viewDetailsTitle' : 'CREDIT_ADJUSTMENT_REPORT.reportStep.inputDetailsTitle';
    }
    updateUploadedDocuments(documents) {
        this.uploadedDocuments = documents;
    }
    setUsedUnitsMap() {
        if (this.data.rowData.defaultCiCode) {
            this.service.currentData.refReportDetails && this.service.currentData.refReportDetails.forEach(refReport => {
                const defaultCIReports = this.service.currentData.reportDetails.filter(report => report.ciSectionCode === this.data.rowData.ciSectionCode && report.referredReportDetailId === refReport.id && report.ciApplicationId !== this.data.rowData.ciModel.ciApplicationId);
                this.usedUnitsMap[refReport.id] = defaultCIReports.reduce((acc, defaultCIReport) => (acc + (+defaultCIReport.complianceCreditQuantity || 0)), 0);
            });
            this.updateAvailabelUnits();
        }
    }
    updateAvailabelUnits() {
        this.data.viewDetailsData.forEach(data => {
            data._quantityAvailable = data.fixedRemaining - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId] + Number(data.otherUsedQuantity));
            data._quantityAvailableRaw = data._quantityAvailable;
        });
    }
    configureSection_90_88_Cols(configs) {
        this.tableConfig = configs;
        let _columns = [];
        const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
        _columns = [
            ...this.updateColumnsConfiguration(columns),
            {
                header: `isOverride_${this.service.currentStep.code}`,
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.CheckboxColTemplate,
            },
            {
                header: 'overriddenValue',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.inputColTemplate,
            },
        ];
        this.tableConfig = Object.assign({}, config, { columns: _columns });
    }
    configureSection_9_3_Cols(configs) {
        this.tableConfig = configs;
        let _columns = [];
        const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
        _columns = [
            ...columns,
        ];
        const indexCI = _columns.findIndex((col) => col.field === 'carbonIntensityFuel');
        if (indexCI !== -1) {
            _columns.splice(indexCI + 1, 0, {
                header: 'carbonIntensityFuel',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.approvedCliValueTemplate,
            }, {
                header: 'complianceCreditQuantity',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.complianceQuantityTemplate,
            }, {
                header: 'energyDensity',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.energyDensityTemplate,
            }, {
                header: 'liquidComplianceCredits',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.liquidComplianceCreditTemplate,
            }, {
                header: 'gaseousComplianceCredits',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.gaseousCompliacneCreditTemplate,
            });
        }
        const index = _columns.findIndex((col) => col.field === 'instrumentId');
        if (index !== -1) {
            _columns.splice(index + 1, 1, {
                header: 'alphanumericId',
                width: '250px',
                templateRef: this.alphanumericIdTemplate
            });
        }
        this.tableConfig = Object.assign({}, config, { columns: _columns });
    }
    setDocConfig() {
        this.documentUploadConfig = {
            documentType: true,
            entityName: 'CREDIT_CREATION_REPORT_ANNUAL_STEP_6_3',
            id: this.data.rowData.ciModel.ciApplicationId,
            comment: true,
            statusColumn: true,
            pendingSubmissionStatus: true,
            disablePaginator: false,
            documentUploadConfigs: [{
                    documentTypeValue: 20010,
                    documentTypeCode: 'CREDIT_ADJUSTMENT_EVIDENCE',
                    documentType: true,
                    id: this.data.rowData.ciModel.ciApplicationId,
                    entityName: 'CREDIT_CREATION_REPORT_ANNUAL_STEP_6_3',
                }],
        };
    }
    updateCalculatedComplianceCredits() {
        if (this.data.rowData.defaultCiCode) {
            return;
        }
        this.data.viewDetailsData.forEach(element => {
            this.calculatedCredits(element);
        });
    }
}
