import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AUTO_WIDTH_FOR_COLUMNS, ROWS_PER_PAGE } from 'app/shared/models/table-view.model';
import { documentsTableConfig } from '@module/account-management/account-management.const';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { GET_ALL } from '@shared/services/upload-document.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { EDIT_DOCUMENT_PUBLIC_VISIBILITY, VIEW_DOCUMENT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import { isNullOrUndefined } from 'util';
import { CiPathPrevSelectedDocComponent } from '@module/ci-pathway-report/components/ci-path-prev-selected-doc/ci-path-prev-selected-doc.component';
export class DocumentsComponent {
    constructor(modalService, uploadDocumentService, store, documentUploadInProgress, translate) {
        this.modalService = modalService;
        this.uploadDocumentService = uploadDocumentService;
        this.store = store;
        this.documentUploadInProgress = documentUploadInProgress;
        this.translate = translate;
        this.disabled = false;
        this.hidden = false;
        this.showPlaceholders = false;
        this.relative = true;
        this.skipFirstLoad = false;
        this.readOnly = false;
        this.ciPathwayReport = true;
        this.documentResult = new EventEmitter();
        this.pagination = {
            page: 0,
            size: ROWS_PER_PAGE,
        };
        this.firstItemIndex = 0;
        this._destroy$ = new Subject();
        this.makePublicEnabled = false;
        this.refreshTableData = new EventEmitter();
    }
    ngOnInit() {
        this.user = this.store.user;
        documentsTableConfig.paginator = this.documentUploadConfig.disablePaginator ? false : documentsTableConfig.paginator;
        const entityNameText = this.documentUploadConfig.guidanceTextEntity ?
            this.documentUploadConfig.guidanceTextEntity : this.documentUploadConfig.entityName;
        this.guidanceText = `COMMON.documentGuidanceTest.${entityNameText}`;
        const { columns } = documentsTableConfig, config = tslib_1.__rest(documentsTableConfig, ["columns"]);
        const _columns = [
            {
                templateRef: this.documentName,
                header: 'name',
                width: AUTO_WIDTH_FOR_COLUMNS,
                sortField: 'name',
            },
            ...documentsTableConfig.columns
        ];
        if (this.documentUploadConfig.publicVisibility && this.user.hasPermission(VIEW_DOCUMENT_PUBLIC_VISIBILITY)) {
            _columns.push({
                header: 'makePublic',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.makePublicTmpl,
            });
        }
        if (this.user.hasPermission('DOCUMENT_UPLOAD') || this.user.hasPermission('DOCUMENT_DOWNLOAD')) {
            _columns.push({
                header: 'actions',
                width: AUTO_WIDTH_FOR_COLUMNS,
                className: 'overflow-visible',
                templateRef: this.accountDocumentActions,
            });
        }
        if (this.documentUploadConfig.statusColumn === false) {
            _columns.splice(_columns.findIndex(col => col.header === 'status'), 1);
        }
        this.tableConfig = Object.assign({}, config, { columns: _columns });
        this.makePublicEnabled = !isNullOrUndefined(this.documentUploadConfig.publicVisibilityEnabled) ?
            this.documentUploadConfig.publicVisibilityEnabled :
            this.user.hasPermission(EDIT_DOCUMENT_PUBLIC_VISIBILITY) ||
                false;
        this.checkDocumentsStatus();
    }
    ngOnChanges(changes) {
        if (changes.documentUploadConfig && !changes.documentUploadConfig.firstChange) {
            this.refreshTable({});
        }
    }
    refreshTable(event) {
        // Marking deleted doc as mandatory record again
        if (event && event.document && event.action === 'DELETE') {
            this.documentUploadConfig.documentUploadConfigs.forEach((doc) => {
                if (doc.documentTypeCode === event.document.type) {
                    doc['alreadyUploaded'] && (doc['alreadyUploaded'] = false);
                }
            });
        }
        // this.documentUploadConfig.id = event.document.id;
        if (this.documentUploadConfig.secondaryEntityId) {
            this.loadDocumentsWithSecondaryEntity(this.pagination);
        }
        else if (this.documentUploadConfig.id && this.documentUploadConfig.entityName) {
            this.uploadDocumentService.getAll(this.pagination, this.documentUploadConfig.id, this.documentUploadConfig.entityName, this.documentUploadConfig.documentTypeCode, this.documentUploadConfig.listUri, this.documentUploadConfig.metaData, this.documentUploadConfig.documentTypeCodes, this.documentUploadConfig.supportingDocumentFilter, this.documentUploadConfig.entityVersionId)
                .subscribe(result => {
                this.documentsPage = result;
                this.showPlaceholderDocuments();
                this.updateCurrentUploadsInProgress();
            });
            this.refreshTableData.emit(event);
        }
        else {
            if ((event.action === 'DELETE' || event.action === 'DELETE_NOT_SUBMITTED') && event.document) {
                const docId = this.documentsPage.content.findIndex(d => d.id === event.document.id);
                this.documentsPage.content.splice(docId, 1);
                this.documentResult.emit(this.documentsPage.content);
                this.loadDocuments(this.pagination);
            }
            else {
                this.loadDocumentsFromMultipleSource(this.pagination);
                this.refreshTableData.emit(event);
            }
        }
    }
    setDocumentPlaceholders() {
        if (!this.documentsPage) {
            this.documentsPage = {
                content: [],
            };
        }
        // marking already viewed mandatory docs as viewed
        if (this.documentUploadConfig.documentUploadConfigs && this.documentUploadConfig.documentUploadConfigs.length) {
            this.documentsPage.content.forEach((doc) => {
                this.documentUploadConfig.documentUploadConfigs.forEach((mandatoryDoc) => {
                    if (mandatoryDoc.documentTypeCode === doc.type) {
                        mandatoryDoc['alreadyUploaded'] = true;
                    }
                });
            });
        }
        if (this.documentsPage) { // not showing missing docs till the other already uploaded docs are left to view
            const alreadyViewedDocsNumber = (this.documentsPage.number * this.documentsPage.size) + this.documentsPage.numberOfElements;
            const areDocsLeftToView = this.documentsPage.totalElements > alreadyViewedDocsNumber;
            if (areDocsLeftToView) {
                return;
            }
        }
        const currentDocTypes = Array.from(new Set(this.documentsPage.content.map(d => d.documentType.code)));
        let missingTypes = this.documentUploadConfig.documentUploadConfigs.filter(dt => !currentDocTypes.includes(dt.documentTypeCode));
        missingTypes = missingTypes.filter((mandatoryDoc) => !mandatoryDoc['alreadyUploaded']); // filtering out already viewed/uploaded mandatory docs
        missingTypes.forEach(docType => {
            this.documentsPage.content.push({
                _placeholder: true,
                type: docType.documentTypeCode,
                documentType: docType,
            });
        });
    }
    openUploadPopup(event, preselectedDocumentType = null) {
        event && event.preventDefault();
        if (this.disabled) {
            return;
        }
        const documentUploadConfig = preselectedDocumentType ? Object.assign({}, this.documentUploadConfig, { preselectedDocumentType }) : this.documentUploadConfig;
        this.modalService.open(UploadDocumentComponent, documentUploadConfig).afterClosed().subscribe((result) => {
            if (result) {
                if (!this.documentsPage) {
                    this.documentsPage = {
                        totalElements: 1,
                        totalPages: 1,
                        last: true,
                        first: true,
                        size: 1,
                        number: 1,
                        numberOfElements: 1,
                        sort: null
                    };
                }
                if (!this.documentsPage.content) {
                    this.documentsPage.content = [];
                }
                this.uploadDocumentService.checkUploadStatus(result.id, result.signedUrl)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(res => {
                    const idx = this.documentsPage.content.findIndex(d => d.id === res.id);
                    if (idx > -1) {
                        if (res.status === 'CLEAN') {
                            const doc = Object.assign({}, this.documentsPage.content[idx], { _uploadStatus: 'CLEAN' });
                            this.documentsPage.content[idx] = doc;
                            this.loadDocuments(this.pagination);
                        }
                        else {
                            this.documentsPage.content.splice(idx, 1);
                            const messages = {
                                messages: [{ message: 'infectedDocument' }],
                                type: "ERROR" /* ERROR */,
                            };
                            this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                                .afterClosed()
                                .subscribe();
                        }
                        this.documentResult.emit(this.documentsPage.content);
                    }
                });
                const documents = this.documentsPage.content;
                const placeholderIdx = documents.findIndex(p => p._placeholder && p.type === result.type);
                if (placeholderIdx > -1) {
                    documents.splice(placeholderIdx, 1);
                }
                documents.unshift(Object.assign({}, result, { _uploadStatus: 'IN_PROGRESS' }));
                this.documentsPage = Object.assign({}, this.documentsPage, { content: documents });
                // var documentIdList: number[] = [];
                // this.documents.content.map(doc => documentIdList.push(doc.id));
                this.documentResult.emit(this.documentsPage.content);
            }
        });
    }
    openPreviousSelectedPopUp(event) {
        this.modalService.open(CiPathPrevSelectedDocComponent, {}, true).afterClosed().subscribe(res => {
            if (res && res.length) {
                let typesInB = new Set(res.map(obj => obj.type));
                this.documentsPage.content = this.documentsPage.content.filter(aObj => !typesInB.has(aObj.type));
                this.documentsPage.content = [...this.documentsPage.content, ...res];
                this.documentsPage = Object.assign({}, this.documentsPage);
                this.documentResult.emit(this.documentsPage.content);
                this.loadDocuments(this.pagination);
            }
        });
    }
    updateCurrentUploadsInProgress() {
        const currentUploads = this.documentUploadInProgress.get();
        currentUploads.forEach(doc => {
            const idx = this.documentsPage.content.findIndex(d => d.id === doc.id);
            if (idx > -1) {
                const docInProgress = this.documentsPage.content[idx];
                docInProgress._uploadStatus = 'IN_PROGRESS';
                this.documentsPage.content[idx] = docInProgress;
                this.documentResult.emit(this.documentsPage.content);
            }
        });
    }
    onPaginationChanged(pagination) {
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        if (!this.skipFirstLoad) {
            this.loadDocuments(this.pagination);
        }
        else if (this.showPlaceholders) {
            this.setDocumentPlaceholders();
        }
        else {
            this.skipFirstLoad = false;
        }
    }
    loadDocuments(pagination) {
        if (this.documentUploadConfig.historicData) {
            this.loadHistoricDocuments(this.documentUploadConfig.documents);
        }
        else if (this.documentUploadConfig.secondaryEntityId) {
            this.loadDocumentsWithSecondaryEntity(pagination);
        }
        else if (this.documentUploadConfig.id && this.documentUploadConfig.entityName) {
            this.uploadDocumentService.getAll(pagination, this.documentUploadConfig.id, this.documentUploadConfig.entityName, this.documentUploadConfig.documentTypeCode, this.documentUploadConfig.listUri, this.documentUploadConfig.metaData, this.documentUploadConfig.documentTypeCodes, this.documentUploadConfig.supportingDocumentFilter, this.documentUploadConfig.entityVersionId)
                .pipe(takeUntil(this._destroy$)).subscribe(data => {
                this.documentsPage = data;
                this.removeDeletedIfDisabled();
                this.showPlaceholderDocuments();
                this.updateCurrentUploadsInProgress();
            });
        }
        else {
            this.loadDocumentsFromMultipleSource(pagination);
        }
    }
    removeDeletedIfDisabled() {
        if (this.disabled && this.documentsPage && this.documentsPage.content) {
            this.documentsPage.content.forEach(element => {
                if (element.actionList) {
                    element.actionList = element.actionList.filter(el => {
                        return el.action !== 'DELETE_NOT_SUBMITTED';
                    });
                }
            });
        }
    }
    loadHistoricDocuments(documents) {
        if (documents) {
            const actualDocs = documents.filter(d => d.id);
            if (actualDocs) {
                actualDocs.forEach(element => element.actionList = [{ action: 'DOWNLOAD', enabled: true }]);
                this.documentsPage = {
                    totalElements: actualDocs.length,
                    totalPages: 1,
                    last: false,
                    first: true,
                    size: 20,
                    number: 0,
                    sort: null,
                    numberOfElements: 20,
                    content: actualDocs,
                };
            }
        }
    }
    loadDocumentsFromMultipleSource(pagination) {
        if (this.documentUploadConfig.documentUploadConfigs && this.documentUploadConfig.documentUploadConfigs.length) {
            const documentUploadConfig1 = this.documentUploadConfig.documentUploadConfigs[0];
            const documentUploadConfig2 = this.documentUploadConfig.documentUploadConfigs[1] ? this.documentUploadConfig.documentUploadConfigs[1] :
                { id: this.documentUploadConfig.documentUploadConfigs[0].overrideId ? this.documentUploadConfig.documentUploadConfigs[0].overrideId : -1,
                    entityName: 'ACCOUNT', documentTypeCode: null };
            const documentTypeCodes = [];
            if (this.documentUploadConfig.documentUploadConfigs.length > 2) {
                for (let i = 1; i < this.documentUploadConfig.documentUploadConfigs.length; i++) {
                    documentTypeCodes.push(this.documentUploadConfig.documentUploadConfigs[i].documentTypeCode);
                }
            }
            const type = documentUploadConfig1.entityName === 'ACCOUNT' && documentUploadConfig1.documentTypeCode === 'AUTHORIZATION_DOCUMENT' ? null : documentUploadConfig1.documentTypeCode;
            forkJoin([
                this.uploadDocumentService.getAll(pagination, documentUploadConfig1.id, documentUploadConfig1.entityName, type, null, documentUploadConfig1.metaData),
                this.uploadDocumentService.getAll(pagination, documentUploadConfig2.id, documentUploadConfig2.entityName, documentUploadConfig2.documentTypeCode, null, null, documentTypeCodes)
            ]).subscribe(([data, data2]) => {
                this.documentsPage = data;
                this.documentsPage.numberOfElements = data.numberOfElements + data2.numberOfElements;
                this.documentsPage.totalElements = data.totalElements + data2.totalElements;
                this.documentsPage.content = this.documentsPage.content.concat(data2.content);
                this.showPlaceholderDocuments();
                this.updateCurrentUploadsInProgress();
            });
        }
    }
    showPlaceholderDocuments() {
        if (this.showPlaceholders) {
            this.documentResult.emit(this.documentsPage.content);
            this.setDocumentPlaceholders();
        }
    }
    onBeforeUnload(event) {
        if (this.documentUploadInProgress.isInProgress()) {
            event.preventDefault();
            event.returnValue = 'Documents are still processing';
            return event;
        }
        return true;
    }
    get uploadInProgress() {
        return this.documentUploadInProgress.inProgress$;
    }
    checkDocumentsStatus() {
        if (!this.documentsPage || !this.documentsPage.content) {
            return;
        }
        const docs = this.documentsPage.content.filter(doc => doc._uploadStatus === 'IN_PROGRESS');
        docs.forEach(document => {
            this.uploadDocumentService.checkUploadStatus(document.id, document.signedUrl)
                .pipe(takeUntil(this._destroy$))
                .subscribe(res => {
                const idx = this.documentsPage.content.findIndex(d => d.id === res.id);
                if (idx > -1) {
                    if (res.status === 'CLEAN') {
                        const doc = Object.assign({}, this.documentsPage.content[idx], { _uploadStatus: 'CLEAN' });
                        this.documentsPage.content[idx] = doc;
                    }
                    else {
                        this.documentsPage.content.splice(idx, 1);
                        const messages = {
                            messages: [{ message: 'infectedDocument' }],
                            type: "ERROR" /* ERROR */,
                        };
                        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                            .afterClosed()
                            .subscribe();
                    }
                    this.documentResult.emit(this.documentsPage.content);
                }
            });
        });
    }
    updateVisibility(doc) {
        this.modalService.open(ServiceMessageComponent, {
            message: doc.isPublic ? 'markDocumentAsPublic' : 'unmarkDocumentAsPublic',
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
                this.setDocumentVisibility(doc);
            }
            else {
                doc.isPublic = !doc.isPublic;
                this.documentResult.emit(this.documentsPage.content);
            }
        });
    }
    setDocumentVisibility(doc) {
        doc._uploadStatus = 'IN_PROGRESS';
        this.uploadDocumentService.updateVisibility(doc.id, doc.isPublic)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => doc._uploadStatus = 'CLEAN', () => doc._uploadStatus = 'CLEAN', () => this.documentResult.emit(this.documentsPage.content));
    }
    loadDocumentsWithSecondaryEntity(pagination) {
        const sub$ = forkJoin([
            this.uploadDocumentService.getAll(pagination, this.documentUploadConfig.secondaryEntityId, this.documentUploadConfig.secondaryEntityName, this.documentUploadConfig.documentTypeCode, this.documentUploadConfig.listUri, this.documentUploadConfig.metaData, this.documentUploadConfig.documentTypeCodes, this.documentUploadConfig.supportingDocumentFilter, this.documentUploadConfig.entityVersionId),
            this.uploadDocumentService.getAll(pagination, this.documentUploadConfig.id, this.documentUploadConfig.entityName, null, GET_ALL, null, null, [], this.documentUploadConfig.entityVersionId)
        ]).subscribe(([data, data2]) => {
            sub$.unsubscribe();
            data.content.forEach(element => {
                element.actionList = [{ action: 'DOWNLOAD', enabled: true }];
                element['makePublicDisabled'] = true;
            });
            this.documentsPage = data;
            this.documentsPage.numberOfElements = data.numberOfElements + data2.numberOfElements;
            this.documentsPage.totalElements = data.totalElements + data2.totalElements;
            this.documentsPage.content = this.documentsPage.content.concat(data2.content);
            this.documentsPage.content = this.documentsPage.content.sort(this._compareFn(this._getNormalizedPagination(pagination)));
            this.showPlaceholderDocuments();
            this.updateCurrentUploadsInProgress();
        });
    }
    _getNormalizedPagination(pagination) {
        // fixes mapping from the table config
        if (pagination.sort === 'timestamp') {
            pagination.sort = 'lastModifiedDateStr';
        }
        return pagination;
    }
    _compareFn(pagination) {
        if (pagination.dir === 'ASC') {
            return (a, b) => (String(a[pagination.sort]).localeCompare(String(b[pagination.sort])));
        }
        return (a, b) => (String(b[pagination.sort]).localeCompare(String(a[pagination.sort])));
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
